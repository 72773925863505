import axiosi from '../Services/axioz';
import global_URL from '../Const/urls';

const API_URL_add = `${global_URL}api/addexpense/`;

const API_URL_update = `${global_URL}api/updateexpense/`;

const API_URL_delete = `${global_URL}api/deleteexpense/`;


// ADDING 
export const addExpense = async (formData) => {
    // Notice we're not converting the formData to JSON
    return await axiosi.post(`${API_URL_add}addExpense`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };


  // UPDATING 
export const updateExpense = async (id, formData) => {
  try{
    await axiosi.put(`${API_URL_update}updateExpense/${id}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }catch(error){
    console.error('Error updating expense:', error);
  }
};


// DETEING 
export const handleDeleteConfirm = async (selectedValue, vals, setVals, setShowDeleteModal) => {
  try {
    await axiosi.delete(`${API_URL_delete}deleteExpense/${selectedValue.id}`);
    setVals(vals.filter((val) => val.id !== selectedValue.id)); // Update products list
    setShowDeleteModal(false); // Close modal after deletion
  } catch (error) {
    console.error('Error deleting expense:', error);
  }
};
  