import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, Col, Pagination, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import Viewer from './viewOrders'; // Import the ViewProduct component
import {branch, username} from '../../../Const/variables';
import { updateOrderStatus } from '../../../Controllers/OrdersController';

const GetOrderz = () => {
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({
    pname: '',
    quantity: '',
    firstName:'',
        phone:'',
    orderedOn:'',
  });


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showViewModal, setShowViewModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${global_URL}api/getorders/getOrders/${branch}`);
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching the orders', error);
    }
  };


  useEffect(() => {
    
    fetchOrders();
  }, []);

  const handleSubmit = async (id,orderStatus,pid,quantity) => {

    const formData = new FormData();
    formData.append('orderStatus', orderStatus);
    formData.append('pId', pid);
    formData.append('quantity', quantity);
    formData.append('soldBy', username);

    try {
     await updateOrderStatus(id,formData);

    } catch (err) {
console.log(err);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
      setFilters({
        ...filters,
        [name]: value
      });
    setCurrentPage(1);
  };

  

  const filteredData = orders.filter((val) =>
    Object.keys(filters).every((key) =>
      val[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );
  
  
  const indexOfLastValue = currentPage * itemsPerPage;
  const indexOfFirstValue = indexOfLastValue - itemsPerPage;
  const currentValue = filteredData.slice(indexOfFirstValue, indexOfLastValue);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };



  const handleViewClick = (val) => {
    setSelectedValue(val);
    setShowViewModal(true);
  };

  const handleConfirmClick = (val) => {
    setSelectedValue(val);
    setShowConfirmModal(true);
  };

  const handlePayClick = (val) => {
    setSelectedValue(val);
    setShowPayModal(true);
  };


  return (
    <div className="container mt-5 p-4">
      <Row className="align-items-center mb-3">
        <Col>
          <h2 style={{ fontWeight: 'bold' }}>Orders</h2>
        </Col>
       
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>
              Product Name
              <Form.Control
                type="text"
                name="pname"
                value={filters.pname}
                onChange={handleFilterChange}
                placeholder="Filter by product name"
              />
            </th>
            <th>
            Quantity
              <Form.Control
                type="text"
                name="quantity"
                value={filters.quantity}
                onChange={handleFilterChange}
                placeholder="Filter by quantity"
              />
            </th>

            <th>
            Ordered By
              <Form.Control
                type="text"
                name="firstName"
                value={filters.firstName}
                onChange={handleFilterChange}
                placeholder="Filter by name"
              />
            </th>

            <th>
           Contacts
              <Form.Control
                type="text"
                name="phone"
                value={filters.phone}
                onChange={handleFilterChange}
                placeholder="Filter by phone"
              />
            </th>

            <th>
            Ordered At
               <Form.Control
                  type="text"
                  name="orderedOn"
                  value={filters.orderedOn}
                  onChange={handleFilterChange}
                  placeholder="YYYY-MM-DD HH:MM:SS"
                 />
             </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentValue.length > 0 ? (
            currentValue.map((data, index) => (
              <tr key={data.id}>
                <td>{indexOfFirstValue + index + 1}</td>
                <td>{data.pname}</td>
                <td>{data.quantity}</td>
                <td>{data.firstName}</td>
                <td>{data.phone}</td>
                <td>{new Date(data.orderedOn).toLocaleString()}</td>
                <td className="d-flex align-items-center">

                {data.orderStatus===0?(
                <Button
                    // variant="warning"
                    className="me-2 bg-warning"
                    onClick={() => handleConfirmClick(data)} // Open view modal
                  >
                    Confirm
                  </Button>
                ):data.orderStatus===2?   (<Button
                  variant="success"
                  className="me-2"// Open view modal
                >
                  Paid
                </Button>):
                (<Button
                  variant="primary"
                  className="me-2 bg-success"
                  onClick={() => handlePayClick(data)} // Open view modal
                >
                  Pay
                </Button>)
                }
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={() => handleViewClick(data)} // Open view modal
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>

                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                Can't find your option, please try again.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <Pagination className="justify-content-center">
        {[...Array(totalPages)].map((_, pageIndex) => (
          <Pagination.Item
            key={pageIndex + 1}
            active={pageIndex + 1 === currentPage}
            onClick={() => handlePageChange(pageIndex + 1)}
            variant="primary"
            className={pageIndex + 1 === currentPage ? 'primary text-white' : 'primary'}
          >
            {pageIndex + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* View Product Modal */}
      <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedValue && <Viewer order={selectedValue} />}
        </Modal.Body>
      </Modal>

      {/* confirm order button  */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to confirm this order?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() =>{ 
              handleSubmit(selectedValue.id,1, selectedValue.pId, selectedValue.quantity )
              fetchOrders()
               setShowConfirmModal(false)   
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>


      {/* pay order button  */}
      <Modal show={showPayModal} onHide={() => setShowPayModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure this order is paid for?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPayModal(false)}>
            Cancel
          </Button>
          <Button
  variant="success"
  onClick={async () => { 
    try {
      // Wait for handleSubmit to complete
      await handleSubmit(selectedValue.id, 2, selectedValue.pId, selectedValue.quantity);

      // Fetch orders after handleSubmit is done
      await fetchOrders();

      // Close the modal after everything is done
      setShowPayModal(false);
    } catch (error) {
      console.error('Error during submission:', error);
      // Handle errors if necessary (e.g., display an error message to the user)
    }
  }}
>
  Yes
</Button>

        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default GetOrderz;
