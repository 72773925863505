import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { updateExpense } from '../../../Controllers/ExpensesController';
import { branch, username } from '../../../Const/variables';

const UpdateExpense = ({ expense, onClose }) => {
  const [formData, setFormData] = useState({
    name:expense.name,
    quantity: expense.quantity,
description: expense.description,
cost:expense.cost,
category: expense.category,
recorderBy: username,
branch: branch
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateExpense(expense.id, formData); // Call the update API
      onClose(); // Close modal after successful update
    } catch (error) {
      console.error('Error updating expense:', error);
      alert('Failed to update expense.');
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName" className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Enter Name"
          />
        </Form.Group>

        <Form.Group controlId="formDesc" className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Enter Description"
          />
        </Form.Group>

        {/* <Form.Group controlId="formCategory" className="mb-3">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type="text"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            placeholder="Enter Category"
          />
        </Form.Group> */}

        {/* <Form.Group controlId="formQuantity" className="mb-3">
          <Form.Label>Quantity</Form.Label>
          <Form.Control
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleInputChange}
            placeholder="Enter Quatity"
          />
        </Form.Group> */}

        <Form.Group controlId="formCost" className="mb-3">
          <Form.Label>Cost</Form.Label>
          <Form.Control
            type="number"
            name="cost"
            value={formData.cost}
            onChange={handleInputChange}
            placeholder="Enter Cost"
          />
        </Form.Group>


        <Button variant="primary" type="submit">
          Update Sale
        </Button>
      </Form>
    </div>
  );
};

export default UpdateExpense;
