import React, { useState } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { updateProduct } from '../../../Controllers/ProductsController'; // Import the update controller
import global_URL from '../../../Const/urls';
import { role } from '../../../Const/variables';

const UpdateProduct = ({ product , onClose}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    pname: product.pname,
    pdescription:product.pdescription,
    pCategoryName: product.pCategoryName,
    pcolor: product.pcolor,
    pprice: product.pprice,
    pquantity: product.pquantity,
    pdiscount:product.pdiscount,
    pcostprice:product.pcostprice,
    pvalue:product.pvalue,
    puom:product.puom,
    psize:product.psize,
    image: null, // For handling file input if image needs to be updated
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      image: file, // Set the image file
    });
    
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prepare form data for the request
    const updatedFormData = new FormData();
    updatedFormData.append('pname', formData.pname);
    updatedFormData.append('pCategoryName', formData.pCategoryName);
    updatedFormData.append('pdescription', formData.pdescription);
    updatedFormData.append('pcolor', formData.pcolor);
    updatedFormData.append('pprice', formData.pprice);
    updatedFormData.append('pquantity', formData.pquantity);
    updatedFormData.append('pdiscount', formData.pdiscount);
    updatedFormData.append('pcostprice', formData.pcostprice);
    updatedFormData.append('pvalue', formData.pvalue);
    updatedFormData.append('puom', formData.puom);
    updatedFormData.append('psize', formData.psize);

    if (formData.image) {
      updatedFormData.append('image', formData.image); // Append image if it's updated
    }

    try {
      await updateProduct(product.id, updatedFormData); // Call the update API
      onClose();
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Failed to update product.');
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formProductName" className="mb-3">
          <Form.Label>Product Name</Form.Label>
          <Form.Control
            type="text"
            name="pname"
            value={formData.pname}
            onChange={handleInputChange}
            placeholder="Enter product name"
          />
        </Form.Group>

        <Form.Group controlId="formDescription" className="mb-3">
  <Form.Label>Description</Form.Label>
  <Form.Control
    as="textarea" // This changes the input to a textarea
    rows={3} // You can specify the number of visible rows
    name="pdescription"
    value={formData.pdescription}
    onChange={handleInputChange}
    placeholder="Enter description"
  />
</Form.Group>

        <Form.Group controlId="formCategoryName" className="mb-3">
          <Form.Label>Category Name</Form.Label>
          <Form.Control
            type="text"
            name="pCategoryName"
            value={formData.pCategoryName}
            onChange={handleInputChange}
            placeholder="Enter category name"
          />
        </Form.Group>

        <Form.Group controlId="formColor" className="mb-3">
          <Form.Label>Color</Form.Label>
          <Form.Control
            type="text"
            name="pcolor"
            value={formData.pcolor}
            onChange={handleInputChange}
            placeholder="Enter color"
          />
        </Form.Group>

        {role==="admin"?(
 <Form.Group controlId="formSize" className="mb-3">
 <Form.Label>Buying Price</Form.Label>
 <Form.Control
   type="number"
   name="pcostprice"
   value={formData.pcostprice}
   onChange={handleInputChange}
   placeholder="Enter price"
 />
</Form.Group>
):(

null
)}

{role==="admin"?(
 <Form.Group controlId="formSize" className="mb-3">
 <Form.Label>Selling Price</Form.Label>
 <Form.Control
   type="number"
   name="pprice"
   value={formData.pprice}
   onChange={handleInputChange}
   placeholder="Enter price"
 />
</Form.Group>
):(

null
)}
       
       {role==="admin"?(
        <Form.Group controlId="formQuantity" className="mb-3">
          <Form.Label>Quantity</Form.Label>
          <Form.Control
            type="number"
            name="pquantity"
            value={formData.pquantity}
            onChange={handleInputChange}
            placeholder="Enter quantity"
          />
        </Form.Group>

):(

  <div></div>
  )}

<Form.Group controlId="formDiscount" className="mb-3">
          <Form.Label>Discount (%)</Form.Label>
          <Form.Control
            type="number"
            name="pdiscount"
            value={formData.pdiscount}
            onChange={handleInputChange}
            placeholder="Enter discount (Ex #30%)"
          />
        </Form.Group>

        <Row>
        <img
                src={`${global_URL}${product.image}`}
                alt={product.pname}
                style={{ width: '150px', height: '150px' }}
              />

                  <Form.Group controlId="formImage" className="mb-3">
                  <Form.Label>Product Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="w-100"
                  />
                  {imagePreview && (
                    <div className="mt-3">
                      <img src={imagePreview} alt="Preview" className="img-thumbnail" width="150" />
                    </div>
                  )}
                </Form.Group>
        </Row>

        <Button variant="primary" type="submit">
          Update Product
        </Button>
      </Form>
    </div>
  );
};

export default UpdateProduct;
